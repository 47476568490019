export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":["he","en"]},
  vueI18nLoader: false,
  locales: [{"code":"he","iso":"he-IL","file":"he.js","name":"עברית","domain":"/he"},{"code":"en","iso":"en-US","file":"en.js","name":"English","domain":"/en"},{"code":"ar","iso":"ar-EG","file":"ar.js","name":"عربي","domain":"/ar"},{"code":"ru","iso":"ru-RU","file":"ru.js","name":"Русский","domain":"/ru"}],
  defaultLocale: "he",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "dictionaries/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"he","iso":"he-IL","file":"he.js","name":"עברית","domain":"/he"},{"code":"en","iso":"en-US","file":"en.js","name":"English","domain":"/en"},{"code":"ar","iso":"ar-EG","file":"ar.js","name":"عربي","domain":"/ar"},{"code":"ru","iso":"ru-RU","file":"ru.js","name":"Русский","domain":"/ru"}],
  localeCodes: ["he","en","ar","ru"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "h",
  3: "e",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: ".",
  14: ".",
  15: "/",
  16: "d",
  17: "i",
  18: "c",
  19: "t",
  20: "i",
  21: "o",
  22: "n",
  23: "a",
  24: "r",
  25: "i",
  26: "e",
  27: "s",
  28: "/",
  29: "h",
  30: "e",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ",",
  36: "\"",
  37: "e",
  38: "n",
  39: ".",
  40: "j",
  41: "s",
  42: "\"",
  43: ":",
  44: "\"",
  45: ".",
  46: ".",
  47: "/",
  48: ".",
  49: ".",
  50: "/",
  51: "d",
  52: "i",
  53: "c",
  54: "t",
  55: "i",
  56: "o",
  57: "n",
  58: "a",
  59: "r",
  60: "i",
  61: "e",
  62: "s",
  63: "/",
  64: "e",
  65: "n",
  66: ".",
  67: "j",
  68: "s",
  69: "\"",
  70: ",",
  71: "\"",
  72: "a",
  73: "r",
  74: ".",
  75: "j",
  76: "s",
  77: "\"",
  78: ":",
  79: "\"",
  80: ".",
  81: ".",
  82: "/",
  83: ".",
  84: ".",
  85: "/",
  86: "d",
  87: "i",
  88: "c",
  89: "t",
  90: "i",
  91: "o",
  92: "n",
  93: "a",
  94: "r",
  95: "i",
  96: "e",
  97: "s",
  98: "/",
  99: "a",
  100: "r",
  101: ".",
  102: "j",
  103: "s",
  104: "\"",
  105: ",",
  106: "\"",
  107: "r",
  108: "u",
  109: ".",
  110: "j",
  111: "s",
  112: "\"",
  113: ":",
  114: "\"",
  115: ".",
  116: ".",
  117: "/",
  118: ".",
  119: ".",
  120: "/",
  121: "d",
  122: "i",
  123: "c",
  124: "t",
  125: "i",
  126: "o",
  127: "n",
  128: "a",
  129: "r",
  130: "i",
  131: "e",
  132: "s",
  133: "/",
  134: "r",
  135: "u",
  136: ".",
  137: "j",
  138: "s",
  139: "\"",
  140: "}",
}

export const localeMessages = {
  'he.js': () => import('../../../dictionaries/he.js' /* webpackChunkName: "lang-he.js" */),
  'en.js': () => import('../../../dictionaries/en.js' /* webpackChunkName: "lang-en.js" */),
  'ar.js': () => import('../../../dictionaries/ar.js' /* webpackChunkName: "lang-ar.js" */),
  'ru.js': () => import('../../../dictionaries/ru.js' /* webpackChunkName: "lang-ru.js" */),
}
